import { useMainStore } from "../store/index";

export default defineNuxtPlugin((app) => {
  const ctm = setTimeout(() => {
    callbackAfterSomeTime(app);
    clearTimeout(ctm);
  }, 6000);
});

function callbackAfterSomeTime() {
  const store = useMainStore();

  const storeData = store.currentSite;

  if (!storeData) return false;

  if (storeData.country.country_code === "mx") {
    const script = document.createElement("script");
    script.src = `https://assets.adobedtm.com/5bac3522cd83/3cea052f37b5/launch-0bb97c098b20.min.js`;
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
