import { useMainStore } from "~/store";

export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.browser) return false;
  const staticArr = ["contact-us", "work-with-us", "about-us"];
  let enabled = false;
  const router = useRouter();
  const arr = ["country-products-slug"];

  const store = useMainStore();
  const pageName = nuxtApp.$getKeyByValue(
    store.pageLinks,
    router.currentRoute.value.params.page
  );
  console.log(staticArr.includes(pageName));
  if (
    (staticArr.includes(pageName) ||
      arr.includes(router.currentRoute.value.name)) &&
    !enabled
  ) {
    import("aos").then((module) => {
      import("aos/dist/aos.css").then((c) => {
        enabled = true;
        // Initialize AOS
        nuxtApp.AOS = new module.init(); // eslint-disable-line new-cap
      });
    });
  }
  nuxtApp.hook("page:start", () => {
    const dynamicName = nuxtApp.$getKeyByValue(
      store.pageLinks,
      router.currentRoute.value.params.page
    );
    if (
      (staticArr.includes(dynamicName) ||
        arr.includes(router.currentRoute.value.name)) &&
      !enabled
    ) {
      import("aos").then((module) => {
        import("aos/dist/aos.css").then((c) => {
          enabled = true;
          // Initialize AOS
          nuxtApp.AOS = new module.init(); // eslint-disable-line new-cap
        });
      });
    }
  });
});
